import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';

import * as dataApi from '../api/dataApi';
import * as webSocketActions from '../redux/actions/webSocketActions';
import * as serviceAddressActions from '../redux/actions/serviceAddressActions';

function SetAddress({ siteConfig, actions, webSocket }) {
  const address = useQueryParam('address', '');
  const secondary = useQueryParam('secondary', '');
  const city = useQueryParam('city', '');
  const state = useQueryParam('state', '');
  const zip = useQueryParam('zip', '');
  const initted = useRef();

  useEffect(() => {
    if (zip && address && webSocket.connected && !initted.current) {
      initted.current = true;

      actions.pageLoading(true, 'Saving your address selection...');
      dataApi
        .saveServiceAddress(address, secondary, city, state, zip, 8)
        .then((result) => {
          if (result && result.Address) {
            actions.serviceAddressUpdated(result);
          }

          actions.pageLoading(false);

          if (siteConfig && siteConfig.id === 'sc') {
            navigate('/electric-rates');
          } else {
            navigate('/dashboard');
          }
        });
    }
  }, [address, secondary, city, state, zip, webSocket]);

  return (
    <div className="page-container">
      <div className="page">
        <div className="inner">
          <div style={{ textAlign: 'center' }}></div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.siteConfig,
    webSocket: state.webSocket
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetAddress);
